import type GiftTemplate from '@consumer/types/GiftTemplate'
import { deepClean, cloneDeep } from '@shared/object'
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from 'lz-string'
import type { MaybeRefOrGetter } from 'vue'
import { defaultV3, v3WithImageBg } from '@consumer/logic/v3'

export function encodeGift (gift: GiftTemplate) {
  return compressToEncodedURIComponent(JSON.stringify(gift))
}

export function decodeGift (encodedGift: string) {
  return JSON.parse(decompressFromEncodedURIComponent(encodedGift)) as GiftTemplate
}

export const useGiftTemplate = (giftRef: MaybeRefOrGetter<GiftTemplate>) => {
  const giftTemplate = computed<GiftTemplate>(() => {
    let {
      amount,
      backgroundImageUrl,
      giftlyDesignName,
      giftlyMetadata,
      giftlyVersion = 3,
      places,
    } = toValue(giftRef)

    giftlyDesignName ||= backgroundImageUrl ? v3WithImageBg : defaultV3

    return deepClean(cloneDeep({
      amount,
      backgroundImageUrl,
      giftlyDesignName,
      giftlyMetadata,
      giftlyVersion,
      places,
    }))
  })

  const giftTemplateParam = computed(() => encodeGift(giftTemplate.value))

  const multiPlaceGiftIdeaTemplate = computed(() => {
    let {
      backgroundImageUrl,
      giftlyDesignName,
      giftlyMetadata,
      places,
    } = toValue(giftRef)

    giftlyDesignName ||= backgroundImageUrl ? v3WithImageBg : defaultV3

    return deepClean(cloneDeep({
      background_image_url: backgroundImageUrl,
      giftly_design_name: giftlyDesignName,
      giftly_metadata: {
        background_color: giftlyMetadata?.backgroundColor,
        mask_name: giftlyMetadata?.maskName,
        mask_color: giftlyMetadata?.maskColor,
        logo_color: giftlyMetadata?.logoColor,
        unsplash_image_id: giftlyMetadata?.unsplashImageId,
        tagline: giftlyMetadata?.tagline,
        design_search_terms: giftlyMetadata?.designSearchTerms,
      },
      places: places?.map((p) => {
        if (p.placeType === 'list') {
          return { url_slug: p.id }
        }
        else if (p.placeType === 'yelp') {
          return { yelp_id: p.id }
        }
        else {
          return { name: p.name, place_type: p.placeType }
        }
      }),
    }))
  })

  return {
    giftTemplate,
    giftTemplateParam,
    multiPlaceGiftIdeaTemplate,
  }
}
